import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/es/layout"
//import Image from "../components/image"
import SEO from "../../components/es/seo"

import MainImg from "./../../images/partnerstvo.jpg"

import shape1 from "./../../images/dec/1.png"
import shape2 from "./../../images/dec/2.png"
import shape3 from "./../../images/dec/3.png"
import shape4 from "./../../images/dec/4.png"
import shape5 from "./../../images/dec/5.png"
import shape6 from "./../../images/dec/6.png"
import shape7 from "./../../images/dec/7.png"
import shape8 from "./../../images/dec/8.png"

const Partnerstvo = () => (
  <Layout>
    <SEO
     title="MOONAMAR – HAZTE SOCIO"
     description="¿Amas la naturaleza y trabajas con productos naturales? Contáctanos! Nosotros, el equipo de MOONAMAR, esperamos tu consulta."
     />

    <section
      id="_heroPage"
      className="hero is-large _partnerstvo"
      style={{ backgroundImage: `url(${MainImg})` }}
    >
      <div id="bgHero">
        <span className="shape1">
          <img src={shape1} alt="Shape 1" />
        </span>
        <span className="shape2">
          <img src={shape2} alt="Shape 2" />
        </span>
        <span className="shape3">
          <img src={shape3} alt="Shape 3" />
        </span>
        <span className="shape4">
          <img src={shape4} alt="Shape 4" />
        </span>
        <span className="shape5">
          <img src={shape5} alt="Shape 5" />
        </span>
        <span className="shape6">
          <img src={shape6} alt="Shape 6" />
        </span>
        <span className="shape7">
          <img src={shape7} alt="Shape 7" />
        </span>
        <span className="shape8">
          <img src={shape2} alt="Shape 8" />
        </span>
      </div>
      <div className="hero-body">
        <div className="container">
          <div className="title">COLABORACIÓN</div>
        </div>
      </div>
    </section>

    <span className="_line" />

    <section id="_pageContent_other" className="section">
      <span className="_dec2">
        <img src={shape8} alt="Dec 2" />
      </span>
      <div className="container">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <div className="columns">
              <div className="column _headline">
                <div className="content">
                  <h2 className="subtitle is-4">
                  Los productos de MOONAMAR son el resultado del más cuidadoso trabajo en fórmulas  con el máximo beneficio para usted y su bienestar.  
                  </h2>
                  <p>
                  Los recursos naturales y el saber sobre su aprovechamiento son casi inagotables. También sabemos  que  el conocimiento y la inspiración no son excluisvos de una sola persona y que éstos pueden ser adquiridos  y utilizados por muchas. Descubrir las opiniones, conocimientos y experiencias de nuestros socios  es uno de los pilares de nuestro trabajo. 
                  </p>
                  <p>
                  Siempre estamos abiertos a consejos, ideas nuevas, inovación en nuestros productos y evidentemente para cualquier colaboración en todos los procesos  de elaboración, desde el desarrollo del producto hasta su venta.
                  </p>
                  <p>
                    <strong>
                    ¿Te guata la naturaleza? ¿Tienes alguna idea o espereiencia con productos naturales y te gustaría compartirla? Estamos deseando que te pongas en contacto con nosotros y que nos pongamos a trabajar juntos.
                    </strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span className="_dec3">
        <img src={shape6} alt="Dec 3" />
      </span>
    </section>
  </Layout>
)

export default Partnerstvo
